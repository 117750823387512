<template>
  <div>
    <div>
      <b-modal
        id="create-devolucao"
        no-close-on-backdrop
        ok-title="Criar Devolução"
        cancel-title="Cancelar"
        cancel-variant="danger"
        @show="resetModal"
        @hidden="resetModal"
        @ok="novaDevolucao"
      >
        <b-row>
          <b-col>
            Motivo da Devolução
            <v-select
              v-model="motivoSelected"
              :options="motivoDevolucao"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            Descrição da Devolução
            <b-form-textarea
              v-model="descricaoDevolucao"
            />
          </b-col>
        </b-row>
      </b-modal>
    </div>

    <div>
      <b-modal
        id="create-devolucao-lote"
        no-close-on-backdrop
        ok-title="Criar Devolução"
        cancel-title="Cancelar"
        cancel-variant="danger"
        @show="resetModal"
        @hidden="resetModal"
        @ok="readExcel(file)"
      >
        <b-row>
          <b-col>
            Motivo da Devolução
            <v-select
              v-model="motivoSelected"
              :options="motivoDevolucao"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            Selecione o arquivo
            <b-form-file
              v-model="file"
              placeholder=""
              drop-placeholder="Arquivo"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            Descirção da Devolução
            <b-form-textarea
              v-model="descricaoDevolucao"
            />
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <b-modal
      id="retirar-devolucao-lote"
      no-close-on-backdrop
      title="Retirar Devolução em lote"
      ok-title="Retirar Devolução em lote"
      :ok-disabled="!file || !descricaoRetiradaDevolucao"
      cancel-title="Cancelar"
      cancel-variant="danger"
      @show="file = null, descricaoRetiradaDevolucao = ''"
      @hidden="file = null, descricaoRetiradaDevolucao = ''"
      @ok="retiradaDevolucaoLote"
    >

      <b-row class="mt-2">
        <b-col>
          Selecione o arquivo
          <b-form-file
            v-model="file"
            placeholder=""
            drop-placeholder="Arquivo"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          Descrição da retirada da devolução
          <b-form-textarea
            v-model="descricaoRetiradaDevolucao"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="modal-filtro"
      title="Filtros"
      size="lg"
      cancel-title="Cancelar"
      cancel-variant="danger"
      ok-title="Filtrar"
      @ok="setFilter"
    >
      <b-row>
        <b-col md="12">
          <label for="credorName">Credor</label>
          <v-select
            id="credorName"
            v-model="credor.selected"
            multiple
            label="title"
            :options="credorOptions"
            :close-on-select="false"
          />
        </b-col>
        <b-col
          md="6"
          class="mt-2"
        >
          <label>Período Vencimento</label>
          <b-row>
            <b-col cols="6">
              <b-form-input
                id="vencimento-inicio"
                v-model="vencimento.inicio"
                locale="pt"
                type="date"
              />
            </b-col>
            <b-col cols="6">
              <b-form-input
                id="vencimento-final"
                v-model="vencimento.final"
                locale="pt"
                type="date"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col
          lg="6"
          class="mt-2"
        >
          <b-row align-h="between">
            <b-col>
              <label>Status</label>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <b-form-checkbox
                v-model="statusOp.exceto"
                value="1"
                unchecked-value="0"
                inline
                class="custom-control-primary"
              >
                Exceto
              </b-form-checkbox>
            </b-col>
          </b-row>
          <v-select
            v-model="statusOp.selected"
            label="title"
            :options="statusOp.options"
          />
        </b-col>
        <b-col
          lg="6"
          class="mt-2"
        >
          <b-row class="justify-content-between">
            <b-col>
              <label>Evento</label>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <b-form-checkbox
                v-model="eventos.exceto"
                value="1"
                inline
                class="custom-control-primary"
              >
                Exceto
              </b-form-checkbox>
            </b-col>
          </b-row>
          <v-select
            v-model="eventos.selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="title"
            :options="eventos.options"
          />
        </b-col>
        <b-col
          lg="6"
          class="mt-2"
        >
          <b-row class="justify-content-between">
            <b-col>
              <label>Motivo Pendência</label>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <b-form-checkbox
                v-model="mtPend.exceto"
                value="1"
                inline
                unchecked-value="0"
                class="custom-control-primary"
              >
                Exceto
              </b-form-checkbox>
            </b-col>
          </b-row>
          <v-select
            v-model="mtPend.selected"
            label="title"
            :options="mtPend.options"
          />
        </b-col>
        <b-col
          lg="6"
          class="mt-2"
        >
          <b-row align-h="between">
            <b-col>
              <label>Classificação Negativação</label>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <b-form-checkbox
                v-model="classNeg.exceto"
                value="1"
                unchecked-value="0"
                inline
                class="custom-control-primary"
              >
                Exceto
              </b-form-checkbox>
            </b-col>
          </b-row>
          <v-select
            v-model="classNeg.selected"
            label="title"
            :options="classNeg.options"
          />
        </b-col>
        <b-col
          lg="6"
          class="mt-2"
        >
          <b-row align-h="between">
            <b-col>
              <label>Classificação Cliente</label>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <b-form-checkbox
                v-model="classCliente.exceto"
                value="1"
                inline
                unchecked-value="0"
                class="custom-control-primary"
              >
                Exceto
              </b-form-checkbox>
            </b-col>
          </b-row>
          <v-select
            v-model="classCliente.selected"
            label="title"
            multiple
            :options="classCliente.options"
          />
        </b-col>
        <b-col
          lg="12"
          class="mt-2"
        >
          <b-row>
            <b-col lg="3">
              <label for="valor-inicio">Inicial R$</label>
              <b-form-input
                id="valor-inicio"
                v-model="valor.inicial"
                placeholder="1.000,00"
              />
            </b-col>
            <b-col lg="3">
              <label for="valor-final">Final R$</label>
              <b-form-input
                id="valor-final"
                v-model="valor.final"
                placeholder="1.000,00"
              />
            </b-col>
            <b-col
              lg="4"
              sm="6"
              class="mt-2"
            >
              <b-form-radio-group
                v-model="valor.select"
                name="radio-validation"
                :options="valor.options"
                value-field="value"
                text-field="text"
                disabled-field="notEnabled"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>

    <b-row>
      <b-col vols="12">
        <b-row
          align-h="between"
          class="mb-2"
        >
          <b-col>
            <b-button
              v-b-modal.modal-filtro
              variant="warning"
            >
              Filtro
            </b-button>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button
              v-b-modal.retirar-devolucao-lote
              class="mr-2"
              variant="warning"
            >
              Retirar Devolução Em Lote
            </b-button>
            <b-button
              v-b-modal.create-devolucao-lote
              variant="primary"
            >
              Devolução em lote
            </b-button>
            <b-button
              v-b-modal.create-devolucao
              variant="primary"
              class="ml-2"
            >
              Criar Devolução
            </b-button>

          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-overlay
            :show="load"
            variant="transparent"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <b-row align-h="between">
                  <b-col
                    lg="1"
                  >
                    <b-form-select
                      v-model="perPage"
                      :options="[10, 15, 20]"
                      size="sm"
                    />
                  </b-col>
                  <b-col
                    lg="8"
                    class="d-flex justify-content-end"
                  >
                    <b-form-input
                      v-model="filter"
                      placeholder="Pesquisar"
                      type="search"
                      size="sm"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-table
                  striped
                  responsive
                  selectable
                  select-mode="single"
                  :items="operacoes"
                  :fields="fields"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  class="text-center"
                  @row-selected="rowClick"
                >
                  <template #head(check)>
                    <b-form-checkbox
                      v-model="selectAll"
                    />
                  </template>
                  <template #cell(check)="data">
                    <b-form-checkbox
                      v-model="op"
                      :value="data.item"
                    />
                  </template>
                </b-table>
              </b-col>
              <b-col cols="12">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="center"
                />
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal"
      hide-footer
      centered
      size="lg"
      title="Detalhes da Operação"
    >
      <InfoCard
        :id-op="detalhesOp"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BTable,
  BButton,
  BModal,
  VBModal,
  BRow,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormRadioGroup,
  BPagination,
  BFormTextarea,
  BFormSelect,
  BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import xlsxFile from 'read-excel-file'
import readXlsxFile from 'read-excel-file'
import axios from '@/../axios-auth'
import InfoCard from '../operacao/cards/OperacoesInfo.vue'
// import OperacoesTable from '@/views/backoffice/operacao/cards/OperacoesTable.vue'
// import Filtro from '@/components/filtros/OperacoesFiltro.vue'

export default {
  components: {
    BOverlay,
    // OperacoesTable,
    // Filtro,
    BFormFile,
    InfoCard,
    BCard,
    BTable,
    BButton,
    BModal,
    BRow,
    BCol,
    vSelect,
    BFormCheckbox,
    BFormInput,
    BFormRadioGroup,
    BPagination,
    BFormTextarea,
    BFormSelect,
  },

  directives: {
    'b-modal': VBModal,
  },

  data() {
    return {
      operacoesList: [],
      file: null,
      load: true,
      selectAll: false,
      credor: {
        credOption: [],
        selected: [],
        ids: [],
      },
      vencimento: {
        inicio: '',
        final: '',
      },
      statusOp: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      eventos: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      mtPend: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      classNeg: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      classCliente: {
        options: [],
        ids: [],
        selected: '',
        exceto: '0',
      },
      valor: {
        options: [
          { text: 'Por Operação', value: 1 },
          { text: 'Ticket Médio Cliente', value: 2, notEnabled: true },
          { text: 'Ticket Médio Operação', value: 3, notEnabled: true },
        ],
        select: 1,
        inicial: 0,
        final: 0,
        avaible: false,
      },
      fields: [
        'check',
        { key: 'numero_operacao', label: 'número operação' },
        'status',
        { key: 'cliente', class: 'text-left' },
        { key: 'credor', class: 'text-left' },
        'data_vencimento',
        { key: 'valor_nominal', class: 'text-left' },
      ],
      operacoes: [],
      todos: [],
      op: [],
      detalhesOp: null,
      filter: '',
      totalRows: 1,
      perPage: 10,
      currentPage: 1,
      // clientesInfo: [],
      // DEVOLUCAO
      motivoSelected: null,
      motivoDevolucao: [],
      descricaoDevolucao: null,
      selected: [],
      descricaoRetiradaDevolucao: '',
    }
  },
  computed: {
    credorOptions() {
      return this.credor.credOption.sort((a, b) => {
        const nameA = a.title.toLowerCase()
        const nameB = b.title.toLowerCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    },
  },

  watch: {
    selectAll(n) {
      n ? this.selecionarTodas() : this.op = []
    },

  },
  mounted() {
    // this.$bvModal.show('modal-filtro')
  },

  async created() {
    // MOTIVO DEVOLUCAO
    await axios.get('api/v1/motivo_devolucao', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
      },
    })
      .then(res => {
        this.motivoDevolucao = []
        // eslint-disable-next-line array-callback-return
        res.data.dados.map(item => {
          this.motivoDevolucao.push({
            label: item.nome,
            id: item.id,
          })
        })
      })

    //  LISTAGEM INICIAL DE OPERACOES
    await axios.get('api/v1/operacoes', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
      },
    }).then(res => {
      this.load = true
      this.operacoes = []
      this.totalRows = ''

      res.data.dados.operacoes.data.map(dt => {
        this.operacoes.unshift({
          id: dt.id,
          numero_operacao: dt.numero_operacao,
          cliente: dt.cliente.nome,
          credor: dt.remessa.credor.nome,
          data_vencimento: this.dataHora(dt.data_vencimento, true),
          data_processamento: (dt.data_processamento),
          valor_nominal: this.valorBr(parseFloat(dt.valor_nominal), true),
          valor_atualizado: this.valorBr((dt.valor_atualizado)),
          descricao: dt.descricao,
          desconto: dt.desconto,
          status: dt.status_operacao.nome,
          cliente_id: dt.cliente_id,
        })
      })
      this.load = false
      this.totalRows = this.operacoes.length
    })

    // CREDORES
    await axios.get('api/v1/credores/', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        const objeto = res.data.dados
        // eslint-disable-next-line array-callback-return
        objeto.map(dt => {
          this.credor.credOption.push({ title: dt.nome, id: dt.id })
        })
      })

    // STATUS OPERAÇÃO
    await axios.get('api/v1/info/statusoperacao', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.statusOp.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })

    // EVENTO
    await axios.get('api/v1/motivo_evento', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.eventos.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })

    // MOTIVO PENDENCIA
    await axios.get('api/v1/info/motivopendencia', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.mtPend.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })

    // CLASSIFICAÇÃO NEGATIVAÇÃO
    await axios.get('api/v1/info/classificacaonegativacao', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.classNeg.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })

    // CLASSIFICAÇÃO Cliente
    await axios.get('api/v1/info/classificacaocliente', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.classCliente.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })
  },

  methods: {
    selecionarTodas() {
      this.op = []
      this.operacoes.map(dt => {
        this.op.push(dt)
      })
    },

    async readExcel(fileName) {
      await readXlsxFile(fileName).then(rows => {
        rows.map(row => {
          typeof (row[0]) === 'number' && typeof (row[2]) === 'number' ? this.operacoesList.push({
            operacao: row[0],
            cliente_id: row[2],
          }) : null
        })
      })

      const body = {
        motivo_devolucao_id: this.motivoSelected.id,
        descricao: this.descricaoDevolucao,
        operacoes: this.operacoesList,
      }

      const headers = {
        Accept: 'Application/json',
        headers: { Authorization: 'Bearer '.concat(localStorage.getItem('token')) },
      }

      axios.post('api/v1/devolucao/store', body, headers)
        .then(res => {
          if (res.status === 200) {
            this.$swal({
              title: 'sucesso',
              text: 'Devolução!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$router.push({ name: 'devolucao' })
          }
        })
        .catch(error => {
          this.operacoesList = []
          this.$swal({
            title: 'Error!',
            text: error.response.data.mensagem,
            icon: 'error',
            showConfirmButton: false,
            timer: 5000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    novaDevolucao() {
      if (this.descricaoDevolucao && this.motivoSelected && this.op.length > 0) {
        const obj = []
        this.op.map(item => {
          obj.push({
            operacao: Number(item.id),
            cliente_id: item.cliente_id,
          })
        })

        const dataDev = {
          motivo_devolucao_id: this.motivoSelected.id,
          descricao: this.descricaoDevolucao,
          operacoes: obj,
        }
        const headers = {
          Accept: 'Application/json',
          headers: { Authorization: 'Bearer '.concat(localStorage.getItem('token')) },
        }

        axios.post('api/v1/devolucao/store', dataDev, headers)
          .then(res => {
            if (res.status === 200) {
              this.$swal({
                title: 'sucesso',
                text: 'Devolução feita com sucesso!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.$router.push({ name: 'devolucao' })
            }
          })
          .catch(error => {
            this.$swal({
              title: 'Error!',
              text: error.response.data.mensagem,
              icon: 'error',
              showConfirmButton: false,
              timer: 5000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
      }
    },
    resetModal() {
      this.motivoSelected = []
      this.descricaoDevolucao = ''
    },

    async setFilter() {
      this.load = true
      this.loadOperacoes = false
      const body = {
        pag: 1,
        item_pag: 15,
        filtros: {
        },
      }
      // VALIDACAO CREDOR
      if (this.credor.selected.length) {
        this.credor.ids = []
        // eslint-disable-next-line array-callback-return
        await this.credor.selected.map(item => {
          this.credor.ids.push(item.id)
        })
        body.filtros.credores = this.credor.ids
      }

      // VALIDACAO VENCIMENTO
      if (this.vencimento.inicio && this.vencimento.final) {
        body.filtros.vencimento = {
          data_inicial: this.vencimento.inicio,
          data_final: this.vencimento.final,
        }
      }

      // VALIDACAO STATUS
      if (this.statusOp.selected) {
        body.filtros.status_operacao = {
          valor: this.statusOp.selected.id,
          // eslint-disable-next-line radix
          exceto: parseInt(this.statusOp.exceto),
        }
      }

      // VALIDACAO EVENTOS
      if (this.eventos.selected) {
        body.filtros.eventos = {
          valor: this.eventos.selected.id,
          // eslint-disable-next-line radix
          exceto: parseInt(this.eventos.exceto),
        }
      }

      // VALIDACAO PENDENCIA
      // if (this.mtPend.selected) {
      //   body.filtros.motivo_pendencia = {
      //     valor: this.mtPend.selected.id,
      //     // eslint-disable-next-line radix
      //     exceto: parseInt(this.mtPend.exceto),
      //   }
      // }

      // VALIDCAO VALOR
      // eslint-disable-next-line no-mixed-operators
      if (this.valor.select && this.valor.inicial !== 0 || this.valor.final !== 0) {
        body.filtros.valor = {
          tipo: this.valor.select,
        }
        if (this.valor.inicial !== 0 && this.valor.inicial !== null) {
          body.filtros.valor.valor_inicial = parseFloat(this.valor.inicial)
        } else if (this.valor.final !== 0 && this.valor.final !== null) {
          body.filtros.valor.valor_final = parseFloat(this.valor.final)
        }
      }

      // VALIDCAO CLASSIFICACAO NEGATIVACAO
      // if (this.classNeg.selected) {
      //   body.filtros.classificacao_negativacao = {
      //     valor: this.classNeg.selected.id,
      //     // eslint-disable-next-line radix
      //     exceto: parseInt(this.classNeg.exceto),
      //   }
      // }

      // VALIDACAO CLASSIFICAÇÃO CLIENTE
      if (this.classCliente.selected.length) {
        this.classCliente.ids = []
        // eslint-disable-next-line array-callback-return
        await this.classCliente.selected.map(item => {
          this.classCliente.ids.push(item.id)
        })
        body.filtros.classificacao_cliente = {
          valor: this.classCliente.ids,
          // eslint-disable-next-line radix
          exceto: parseInt(this.classCliente.exceto),
        }
      }
      // adicionado esse filtro para diferenciar no back quando é devolucao
      body.filtros.tipo_filtro = ['devolucao']
      if (Object.keys(body.filtros).length === 0) {
        this.$swal({
          title: 'Error!',
          text: 'Selecione pelo menos 1 filtro ',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        await axios.post('api/v1/operacoes/filtro/devolucao', body, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        }).then(res => {
          this.totalRows = ''
          this.operacoes = []
          // eslint-disable-next-line array-callback-return
          res.data.dados.operacoes.map(dt => {
            this.operacoes.push({
              id: dt.id,
              numero_operacao: dt.operacao,
              cliente: dt.cliente,
              credor: dt.credor,
              data_vencimento: dt.data_vencimento ? this.dataHora(dt.data_vencimento, true) : '',
              data_processamento: dt.data_processamento ? this.dataHora(dt.data_processamento) : '',
              valor_nominal: dt.valor_nominal ? this.valorBr(parseFloat(dt.valor_nominal), true) : '',
              valor_atualizado: dt.valor_atualizado ? this.valorBr((dt.valor_atualizado)) : '',
              descricao: dt.descricao,
              desconto: dt.desconto,
              status: dt.status_operacao.nome,
              cliente_id: dt.cliente_id,

              // cliente_nome: dt.nome_cliente,
              // cliente_cpf_cnpj: dt.cpf_cnpj,
              // cliente_endereco: dt.endereco,
              // cliente_bairro: dt.bairro,
              // cliente_cep: dt.cep,

            })
            this.totalRows = this.operacoes.length
          })
          this.load = false
        })
      }
    },
    async rowClick(item) {
      this.detalhesOp = null
      item.map(dt => {
        this.detalhesOp = dt.id
      })
      this.$bvModal.show('modal')
    },
    retiradaDevolucaoLote() {
      const body = new FormData()

      body.append('planilha', this.file)
      body.append('motivo', this.descricaoRetiradaDevolucao)

      axios
        .post('api/v1/devolucao/cancelar/lote', body, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.file = null
          this.descricaoRetiradaDevolucao = ''
          this.$swal({
            title: 'sucesso',
            text: 'Retirado devolução em lote com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: error.response.data.mensagem,
            icon: 'error',
            showConfirmButton: false,
            timer: 5000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
  },
}

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
